body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.top-bar {
  background-color: #4a90e2;
  color: white;
  padding: 5px 0;
  text-align: center;
  font-size: 2rem;
}

.main-content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  background-color: white;
  color: black;
  text-align: center;
  padding: 20px 0;
  margin-top: auto;
}

.centered-box {
  text-align: center;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #dfdfdf;
  background-color: #fff;
}

input {
  margin-bottom: 20px;
}

.grade-wise-title {
  font-size: 70px;
  font-weight: 500;
}

.custom-navbar {
  background-color: #4a90e2;
  color: white;
}

.custom-navbar .navbar-brand, .custom-navbar .nav-link {
  color: white;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.user-email {
  font-size: 0.8rem;
  align-self: flex-end;
}

.navbar-nav {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.navbar-nav .nav-link {
  font-size: 1rem;
  margin-left: 1rem;
}

.content-limit {
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .custom-navbar .navbar-collapse {
    justify-content: center;
  }
}

.description-limit {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
}
